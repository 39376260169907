import { ref, onMounted } from "vue";
import { templateRef } from "@vueuse/core";

export default {
  __name: 'index',
  props: {
  poster: {
    type: String,
    required: false,
  },
  src: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  preload: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "none"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  replayMode: {
    type: String,
    required: false,
    validator: function (value) {
      return ["restart", "resume"].indexOf(value) !== -1;
    },
    default: "resume",
  },
  useIntersectionObserver: {
    type: Boolean,
    required: false,
    default: true,
  },
  width: {
    type: Number,
    required: false,
    validator: function (value) {
      return value > 0;
    },
  },
  height: {
    type: Number,
    required: false,
    validator: function (value) {
      return value > 0;
    },
  },
},
  setup(__props) {

const props = __props


const tpl_root = templateRef("tpl_root");

function isInViewport() {
  if (!props.useIntersectionObserver) {
    return;
  }
  if (props.replayMode === "restart") {
    tpl_root.value.currentTime = 0;
  }
  tpl_root.value.play();
}

function hasFullyExitViewport() {
  if (!props.useIntersectionObserver) {
    return;
  }
  tpl_root.value.pause();
}

onMounted(() => {
  if (!props.useIntersectionObserver) {
    tpl_root.value.play();
  }
});

return { __sfc: true,props, tpl_root, isInViewport, hasFullyExitViewport }
}

}