const headings = {
  head() {
    if (!this.data?.headings) {
      console.warn("headings not found!");
      return;
    }

    const meta = JSON.parse(JSON.stringify(this.data.headings.meta));

    if (meta[1]) {
      meta.push({
        name: "description",
        hid: "description",
        content: meta[1].content,
      });
    }

    const protocol = process.env.NODE_ENV ? "http://" : "https://";
    const host = this.$store.state.host;
    const link = [];
    for (let i = 0; i < this.$store.state.langs.length; i++) {
      if (this.$store.state.langs[i] != this.$store.state.lang) {
        link.push({
          rel: "alternate",
          hreflang: this.$store.state.langs[i],
          href:
            `${protocol}${host}` +
            this.$route.fullPath.replace(
              `/${this.$route.params.locale}`,
              `/${this.$store.state.langs[i]}-${this.$store.state.market}`
            ),
        });
      }
    }

    return {
      title: this.data.headings.meta[0].content,
      htmlAttrs: {
        lang: this.$store.state.currentLangCode,
      },
      link: link,
      meta: meta,
      script: this.data.headings.script || [],
      __dangerouslyDisableSanitizers: ["script"],
    };
  },
};

export default headings;
