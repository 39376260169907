import { ref, onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  dataProvider: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props



const showModal = ref(false);
const { reveal } = useScrollTriggerAnimation();
const tpl_buttonWrapper = templateRef("tpl_buttonWrapper");
const tpl_button = templateRef("tpl_button");

onMounted(() => {
  reveal(tpl_button.value.$el, tpl_buttonWrapper.value, "scale-up", {
    intensity: "weak",
    fade: true,
    duration: "long",
  });
});

return { __sfc: true,props, showModal, reveal, tpl_buttonWrapper, tpl_button }
}

}