import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  media: {
    type: Object,
    required: true,
  },
  mediaSizes: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  overline: {
    type: String,
    required: false,
  },
  text: {
    type: String,
    required: false,
  },
  overlineBis: {
    type: String,
    required: false,
  },
  textBis: {
    type: String,
    required: false,
  },
  to: {
    type: String,
    required: true,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "eager", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
},
  setup(__props) {

const props = __props



const tpl_figcaption = templateRef("tpl_figcaption");
const { reveal } = useScrollTriggerAnimation();

onMounted(() => {
  if (props.overline || props.text) {
    reveal(tpl_figcaption.value, tpl_figcaption.value, "right", {
      fade: true,
      duration: "long",
    });
  }
});

return { __sfc: true,props, tpl_figcaption, reveal }
}

}