import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  media: {
    type: Object,
    required: true,
  },
  mediaSizes: {
    type: [Number, Object],
    required: true,
  },
  aspectRatio: {
    type: String,
    required: false,
    validator: function (value) {
      return ["4 / 5", "1 / 1", "3 / 2"].indexOf(value) !== -1;
    },
    default: "4 / 5",
  },
  overline: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: false,
  },
  to: {
    type: String,
    required: true,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "eager", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
},
  setup(__props) {

const props = __props



const tpl_figcaption = templateRef("tpl_figcaption");
const tpl_figcaptionInner = templateRef("tpl_figcaptionInner");
const { reveal } = useScrollTriggerAnimation();

onMounted(() => {
  reveal(tpl_figcaptionInner.value, tpl_figcaptionInner.value, "right", {
    fade: true,
  });
});

return { __sfc: true,props, tpl_figcaption, tpl_figcaptionInner, reveal }
}

}