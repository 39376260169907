import { watch, onMounted, nextTick } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: false,
  },
  items: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props



const tpl_grid = templateRef("tpl_grid");
const { reveal } = useScrollTriggerAnimation();

watch(() => props.items, animate);

function animate() {
  nextTick(() => {
    if (props.title) {
      const $target = tpl_grid.value.$el.querySelector("header > *");
      reveal($target, $target, "bottom", {
        intensity: "strong",
        fade: true,
      });
    }

    ScrollTrigger.refresh(true);
  });
}

onMounted(() => {
  animate();
});

return { __sfc: true,props, tpl_grid, reveal, animate }
}

}