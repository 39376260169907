
import axios from "axios";
import headings from "~/mixins/headings.js";

export default {
  mixins: [headings],
  async asyncData({ store, error, $config }) {
    try {
      /* const response = await axios.get(
        process.client
          ? `${store.state.wsPath}get?type=page&page=homepage&lang=${store.state.lang}&market=${store.state.market}`
          : `${$config.wsURL}/wp-content/json/cached/type-pg-homepage-lng-${store.state.lang}-mrkt-${store.state.market}.json`
      ); */
      const response = await axios.get(
        `${store.state.wsPath}get?type=page&page=homepage&lang=${store.state.lang}&market=${store.state.market}`
      );

      return {
        data: { ...response.data },
      };
    } catch (e) {
      error({ statusCode: 500, message: store.state.labels.error_occurred });
    }
  },
};
