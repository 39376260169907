import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  tag: {
    type: String,
    required: false,
    default: "div",
  },
  variant: {
    type: String,
    required: false,
    validator: function (value) {
      return ["normal", "subtitle"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  reveal: {
    type: String,
    required: false,
    validator: function (value) {
      return ["top", "right", "bottom", "left", "none"].indexOf(value) !== -1;
    },
    default: "bottom",
  },
  text: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props



const { reveal } = useScrollTriggerAnimation();
const tpl_root = templateRef("tpl_root");
const tpl_paragraph = templateRef("tpl_paragraph");

onMounted(() => {
  if (props.reveal !== "none") {
    reveal(tpl_paragraph.value, tpl_root.value, props.reveal, {
      duration: "long",
      intensity: "strong",
      fade: true,
    });
  }
});

return { __sfc: true,props, reveal, tpl_root, tpl_paragraph }
}

}