import { ref, onMounted, onBeforeUnmount, nextTick } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  items: {
    type: Array,
    required: true,
  },
  primaryMediaSizes: {
    type: Object,
    required: true,
  },
  primaryMediaWidth: {
    type: [String, Number],
    required: true,
  },
  primaryMediaHeight: {
    type: [String, Number],
    required: true,
  },
  secondaryMediaSizes: {
    type: Object,
    required: true,
  },
  skin: {
    type: String,
    required: false,
    validator: function (value) {
      return ["red", "gray"].indexOf(value) !== -1;
    },
    default: "red",
  },
  variant: {
    type: String,
    required: false,
    validator: function (value) {
      return ["double", "triple"].indexOf(value) !== -1;
    },
    default: "double",
  },
},
  setup(__props) {

const props = __props



function hideModalVideoPlayer() {
  showVPModal.value = false;
  if (swiperCarouselPrimary.value) swiperCarouselPrimary.value.update();
}

const isIOS = ref(false);
const { reveal } = useScrollTriggerAnimation();
const showVPModal = ref(false);
const tpl_root = templateRef("tpl_root");

const tpl_carouselSecondary = templateRef("tpl_carouselSecondary");
const swiperCarouselSecondary = ref(null);

const tpl_carouselPrimary = templateRef("tpl_carouselPrimary");
const swiperCarouselPrimary = ref(null);

const tpl_carouselText = templateRef("tpl_carouselText");
const swiperText = ref(null);

const tpl_controlsMobile = templateRef("tpl_controlsMobile");
const tpl_controlsDesktop = templateRef("tpl_controlsDesktop");

function handlePrevNext(goto) {
  goto === "next"
    ? swiperCarouselPrimary.value.slideNext()
    : swiperCarouselPrimary.value.slidePrev();
}

const realIndex = ref(0);

onMounted(() => {
  isIOS.value = /iPhone|iPad|iPod/.test(navigator.userAgent);

  swiperCarouselPrimary.value = new Swiper(tpl_carouselPrimary.value, {
    loop: props.items.length > 1,
    grabCursor: props.items.length > 1,
    allowTouchMove: false,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    speed: 800,
    parallax: { enabled: true },
    preventInteractionOnTransition: true,
    on: {
      resize: function (sw) {
        nextTick(() => {
          sw.update();
          ScrollTrigger.refresh(true);
        });
      },
      slideChange: function (sw) {
        realIndex.value = sw.realIndex;
        setTimeout(() => {
          if (
            sw.slides[sw.activeIndex].classList.contains(
              "swiper-slide-duplicate"
            )
          ) {
            const $vid = sw.slides[sw.activeIndex].querySelector("video");
            if ($vid) {
              $vid.play();
            }
          } else {
            sw.slides[sw.activeIndex]
              .querySelectorAll("swiper-slide-duplicate")
              .forEach((el) => {
                const $vid = el.querySelector("video");
                if ($vid) {
                  $vid.pause();
                }
              });
          }
        }, 500);
      },
    },
  });

  swiperText.value = new Swiper(tpl_carouselText.value, {
    allowTouchMove: false,
    loop: props.items.length > 1,
    slidesPerView: 1,
    autoHeight: true,
    parallax: { enabled: true },
    breakpoints: {
      1280: {
        spaceBetween: 300,
      },
    },
  });

  if (props.variant === "triple") {
    swiperCarouselSecondary.value = new Swiper(tpl_carouselSecondary.value, {
      allowTouchMove: false,
      loop: props.items.length > 1,
      slidesPerView: 1,
      autoHeight: false,
      parallax: { enabled: true },
    });

    swiperCarouselPrimary.value.controller.control =
      swiperCarouselSecondary.value;
    swiperCarouselSecondary.value.controller.control = [
      swiperText.value,
      swiperCarouselPrimary.value,
    ];
    swiperText.value.controller.control = swiperCarouselSecondary.value;
  } else {
    swiperCarouselPrimary.value.controller.control = swiperText.value;
    swiperText.value.controller.control = swiperCarouselPrimary.value;
  }

  reveal(tpl_carouselText.value, tpl_carouselText.value, "right", {
    fade: true,
  });

  reveal(tpl_carouselPrimary.value, tpl_carouselPrimary.value, "scale-down", {
    intensity: "weak",
  });

  if (props.variant === "triple") {
    reveal(tpl_carouselSecondary.value, tpl_carouselSecondary.value, "right", {
      intensity: "strong",
    });
  }

  // mobile fix
  if (props.items.length > 1) {
    setTimeout(() => {
      swiperCarouselPrimary.value.loopDestroy();
      swiperCarouselPrimary.value.loopCreate();
      swiperCarouselPrimary.value.update();
    }, 1000);
  }
});

onBeforeUnmount(() => {
  showVPModal.value = false;
});

return { __sfc: true,props, hideModalVideoPlayer, isIOS, reveal, showVPModal, tpl_root, tpl_carouselSecondary, swiperCarouselSecondary, tpl_carouselPrimary, swiperCarouselPrimary, tpl_carouselText, swiperText, tpl_controlsMobile, tpl_controlsDesktop, handlePrevNext, realIndex }
}

}