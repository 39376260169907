import { ref, onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  items: {
    type: Array,
    required: true,
  },
  mediaSizes: {
    type: Object,
    required: true,
  },
  align: {
    type: String,
    required: false,
    validator: function (value) {
      return ["start", "end"].indexOf(value) !== -1;
    },
    default: "start",
  },
  skin: {
    type: String,
    required: false,
    validator: function (value) {
      return ["white", "red", "gray"].indexOf(value) !== -1;
    },
    default: "white",
  },
},
  setup(__props) {

const props = __props



const { reveal } = useScrollTriggerAnimation();

const tpl_textCarouselWrapper = templateRef("tpl_textCarouselWrapper");
const tpl_textCarousel = templateRef("tpl_textCarousel");
const tpl_mediaCarouselWrapper = templateRef("tpl_mediaCarouselWrapper");
const tpl_mediaCarousel = templateRef("tpl_mediaCarousel");
const swiperText = ref(null);
const swiperMedia = ref(null);

onMounted(() => {
  swiperText.value = new Swiper(tpl_textCarousel.value, {
    loop: props.items.length > 1 ? true : false,
    slidesPerView: 1,
    grabCursor: false,
    allowTouchMove: false,
    parallax: true,
    speed: 600,
  });
  swiperMedia.value = new Swiper(tpl_mediaCarousel.value, {
    loop: props.items.length > 1 ? true : false,
    allowTouchMove: props.items.length > 1 ? true : false,
    grabCursor: props.items.length > 1 ? true : false,
    slidesPerView: 1,
    parallax: true,
    speed: 600,
  });
  swiperText.value.controller.control = swiperMedia.value;
  swiperMedia.value.controller.control = swiperText.value;
  reveal(
    tpl_textCarousel.value,
    tpl_textCarouselWrapper.value,
    props.align === "start" ? "right" : "left",
    {
      intensity: "strong",
      fade: false,
      duration: "long",
    }
  );
  reveal(tpl_mediaCarousel.value, tpl_mediaCarouselWrapper.value, "bottom", {
    intensity: "normal",
    fade: true,
    duration: "long",
  });
});

function handlePrevNext(goto) {
  goto === "next" ? swiperText.value.slideNext() : swiperText.value.slidePrev();
}

return { __sfc: true,props, reveal, tpl_textCarouselWrapper, tpl_textCarousel, tpl_mediaCarouselWrapper, tpl_mediaCarousel, swiperText, swiperMedia, handlePrevNext }
}

}