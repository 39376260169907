import { watch, onMounted, nextTick } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  overline: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  items: {
    type: Array,
    required: true,
  },
  mediaSizes: {
    type: Object,
    required: true,
  },
  layout: {
    type: String,
    validator: function (value) {
      return ["regular", "alternate"].indexOf(value) !== -1;
    },
    default: "regular",
  },
},
  setup(__props) {

const props = __props


const tpl_root = templateRef("tpl_root");
const { reveal } = useScrollTriggerAnimation();

watch(() => props.items, animate);

function animate() {
  nextTick(() => {
    if (props.overline) {
      reveal(
        tpl_root.value.$el.querySelector("header >*"),
        tpl_root.value.$el.querySelector("header"),
        "bottom",
        {
          intensity: "normal",
          fade: true,
          duration: "long",
        }
      );
    }

    if (props.title) {
      const $target = tpl_root.value.$el.querySelector("header >*");

      reveal($target, $target, "bottom", {
        intensity: "strong",
        fade: true,
      });
    }

    ScrollTrigger.refresh(true);
  });
}

onMounted(() => {
  animate();
});

function indexIsModOf(index, mod) {
  if (mod == 1) {
    return index == 0 || index % 4 == 0;
  } else if (mod == 2) {
    return index > 0 && (index + 1) % 2 == 0 && (index + 1) % 4 != 0;
  } else if (mod == 3) {
    return index > 0 && (index + 1) % 4 == 3;
  } else if (mod == 4) {
    return index > 0 && (index + 1) % 4 == 0;
  }
}

return { __sfc: true,props, tpl_root, reveal, animate, indexIsModOf }
}

}